import React from 'react'
import styled from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import NetworkWhitepaper from './assets/NetworkWhitepaper'
import MulticastEncryption from './assets/MulticastEncryption'
import Lightpaper from './assets/Lightpaper'
import Governance from './assets/Governance'
import TokenSale from './assets/TokenSale'
import Whitepaper from './assets/Whitepaper'
import LimeChainAudit from './assets/LimeChainAudit'
import IsentropyAudit from './assets/IsentropyAudit'
import TokenomicsAudit from './assets/TokenomicsAudit'
import Layout from '~shared/Layout'
import urls from '~utils/urls'
import { Link } from 'streamr-ui'
import Spacer from '~shared/Spacer'
import { Headline, Paragraph } from '~/components/DataFund/Hero'

const PapersLayout = styled(Layout)`
    color: #0c009a;

    ${Layout.Inner} {
        background-color: #ffffff;
        color: #0c009a;
        padding-top: 64px;
    }

    @media ${MqDesktop} {
        ${Layout.Inner} {
            padding-top: 72px;
        }
    }
`

const Hero = styled.div`
    background-color: #ffffff;
    margin: 0 auto;
    padding: 5.5rem 2rem;

    @media ${MqTablet} {
        width: 580px;
    }

    @media ${MqDesktop} {
        width: 580px;
    }

    p {
        font-size: 16px;
        line-height: 28px;
        margin: 0;
    }

    @media ${MqDesktop} {
        p {
            font-size: 18px;
            line-height: 30px;
        }
    }
`

const PaperContainer = styled.div`
    background-color: #efefef;
    padding: 5.5rem 2rem;

    @media ${MqTablet} {
        padding: 12rem 7.5rem;
    }

    @media ${MqDesktop} {
        padding: 11rem 13.5rem;
    }
`

const PaperList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 144px);
    grid-column-gap: 24px;
    grid-row-gap: 65px;
    justify-content: center;

    @media ${MqTablet} {
        grid-template-columns: repeat(auto-fit, 224px);
        grid-column-gap: 80px;
        grid-row-gap: 65px;
    }

    @media ${MqDesktop} {
        grid-template-columns: repeat(auto-fit, 224px);
        grid-column-gap: 76px;
    }
`

const PaperImage = styled.div`
    border-radius: 3px;
    display: block;
    overflow: hidden;
    transition: 300ms transform ease-out;
    width: 100%;
`

const Paper = styled(Link)`
    text-decoration: none !important;

    :hover ${PaperImage} {
        transform: scale(1.05);
        transition-duration: 50ms;
    }
`

const Name = styled.div`
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-top: 16px;

    @media ${MqTablet} {
        font-size: 18px;
        line-height: 24px;
        margin-top: 30px;
    }

    @media ${MqDesktop} {
        font-size: 18px;
        line-height: 24px;
        margin-top: 36px;
    }
`

const Date = styled.div`
    color: #a3a3a3;
    font-size: 12px;
    line-height: 20px;

    @media ${MqTablet} {
        font-size: 18px;
        line-height: 24px;
    }

    @media ${MqDesktop} {
        font-size: 14px;
        line-height: 24px;
    }
`

const Papers = () => {
    return (
        <PapersLayout $backgroundColor="white">
            <Hero>
                <Spacer tail="32px,,">
                    <Headline as="h1" $appear>
                        Streamr Papers
                    </Headline>
                </Spacer>
                <Paragraph $appear>
                    Get access to the range of project-related technical and academic papers the
                    Streamr team have published here, from the original whitepaper to governance,
                    audits and technical ideas. All new publications will be made available on this
                    page.
                </Paragraph>
            </Hero>
            <PaperContainer>
                <PaperList>
                    <Paper href={urls.documents.tokenomicsAudit} blank $appear>
                        <PaperImage as={TokenomicsAudit} />
                        <div $appear>
                            <Name>Tokenomics audit</Name>
                            <Date>November 3, 2023</Date>
                        </div>
                    </Paper>
                    <Paper href={urls.documents.lightpaper} blank $appear>
                        <PaperImage as={Lightpaper} />
                        <div $appear>
                            <Name>Streamr lightpaper</Name>
                            <Date>May 4, 2022</Date>
                        </div>
                    </Paper>
                    <Paper href={urls.documents.networkWhitepaper} blank $appear>
                        <PaperImage as={NetworkWhitepaper} />
                        <div $appear>
                            <Name>Network whitepaper</Name>
                            <Date>August 20, 2020</Date>
                        </div>
                    </Paper>
                    <Paper href={urls.documents.multicastEncryption} blank $appear>
                        <PaperImage as={MulticastEncryption} />
                        <div $appear>
                            <Name>Multicast Encryption</Name>
                            <Date>August 22, 2022</Date>
                        </div>
                    </Paper>
                    <Paper href={urls.documents.limeChainAudit} blank $appear>
                        <PaperImage as={LimeChainAudit} />
                        <div $appear>
                            <Name>DATAv2 audit (LimeChain)</Name>
                            <Date>July 13, 2021</Date>
                        </div>
                    </Paper>
                    <Paper href={urls.documents.isentropyAudit} blank $appear>
                        <PaperImage as={IsentropyAudit} />
                        <div $appear>
                            <Name>DATAv2 audit (Isentropy)</Name>
                            <Date>June 29, 2021</Date>
                        </div>
                    </Paper>
                    <Paper href={urls.documents.governanceWhitepaper} blank $appear>
                        <PaperImage as={Governance} />
                        <div $appear>
                            <Name>Decentralized governance</Name>
                            <Date>March 5, 2020</Date>
                        </div>
                    </Paper>
                    <Paper href={urls.documents.tokenAudit} blank $appear>
                        <PaperImage as={TokenSale} />
                        <div $appear>
                            <Name>Token sale audit</Name>
                            <Date>October 27, 2017</Date>
                        </div>
                    </Paper>
                    <Paper href={urls.documents.whitepaper} blank $appear>
                        <PaperImage as={Whitepaper} />
                        <div $appear>
                            <Name>Streamr whitepaper</Name>
                            <Date>July 25, 2017</Date>
                        </div>
                    </Paper>
                </PaperList>
            </PaperContainer>
        </PapersLayout>
    )
}

export default Papers
